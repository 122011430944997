import React, { Component } from 'react';
import { THEMES } from "../context/withApp";
import Page from "../components/Page/Page";
import SEO from "../components/SEO/SEO";
import Grid, { Col } from '../components/UI/Grid/Grid';
// import PropTypes from 'prop-types';

import type from '../styles/typography.module.scss';
import styles from './expertise.module.scss';

import SymbolDesign from '../assets/symbol-design.inline.svg';
import SymbolFrontend from '../assets/symbol-frontend.inline.svg';

class Expertise extends Component {
    render() {
        return (
            <Page { ...this.props.page } theme={ THEMES.light }>
                <SEO title="Expertise" description="Expertise in Digital Design & Front-end Development" keywords={[`digital`, `design`, `front-end development`, `freelance`, `portfolio`, `southampton`, `hampshire`]} setPageTitle={true}/>

                <h1>Perfection, as standard.</h1>
                <p className={type.lead}>Working as a multi-disciplined freelance designer and front-end developer, I strive for perfection, delivering the highest quality design, development & user centred results. For me, quality in everything I do is paramount, I never cut corners – do it once, do it right has always been my motto.</p>

                <section className={styles.section}>
                    <h2 className={styles.heading}><SymbolDesign/> Digital Design</h2>

                    <Grid>
                        <div className={styles.body}>
                            <p>Creating beautiful bespoke digital experiences is my forte. I believe that you can only make something amazing if you have the passion and full understanding of the medium – as such my expertise covers the full process, from information architecture, wireframes, concepts and prototypes to pixel perfect designs and everything in-between.</p>
                            <p>With a strong focus on colour, space and typography, I ensure the finished product is memorable, on brand, usable and, most importantly, achieves results.</p>
                            <p>From product sites and online storefronts to web apps, I’ve done it all.</p>
                        </div>
                        <Col>
                            <h3>Services</h3>
                            <ul className={styles.services}>
                                <li>Website design</li>
                                <li>Web app design</li>
                                <li>Prototyping</li>
                                <li>Wireframing</li>
                                <li>Interaction design</li>
                                <li>User Interface design</li>
                                <li>Responsive design</li>
                            </ul>
                        </Col>
                    </Grid>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.heading}><SymbolFrontend/> Front-end Development</h2>

                    <Grid>
                        <div className={styles.body}>
                            <p>With 15+ years’ professional experience, projects are delivered on time, within budget and with polish few can match. As I also specialise in design, it gives me a unique eye for creative detail amongst my peers which means bugs relating to UI are few and far between. This also enables me to make decisions autonomously during the responsive development of projects that I didn’t design myself.</p>
                            <p>I have developed complex front-ends for large scale sites and, as such, I understand the importance of making sure my code is predictable, reusable, maintainable and scalable. Along with the typical skills a front-end developer might have, I also have a large amount of experience with performance and accessibility.</p>
                            <p>As for my JavaScript abilities, I utilise the latest techniques, features and tooling. Equally at home in vanilla JS as in a framework, I create successful web applications and functionality that are well tested, modular and performant.</p>
                        </div>
                        <Col>
                            <h3>Skills</h3>

                            <ul className={styles.skills}>
                                <li>
                                    <h4 className={styles.skillTitle}>HTML</h4>
                                    <ul className={styles.skillList}>
                                        <li>Document structure</li>
                                        <li>Semantic markup</li>
                                        <li>Accessibility</li>
                                    </ul>
                                </li>
                                <li>
                                    <h4 className={styles.skillTitle}>CSS</h4>
                                    <ul className={styles.skillList}>
                                        <li>Sass</li>
                                        <li>Less</li>
                                        <li>BEM</li>
                                        <li>Suit CSS</li>
                                        <li>CSS Modules</li>
                                        <li>PostCSS</li>
                                    </ul>
                                </li>
                                <li>
                                    <h4 className={styles.skillTitle}>Javascript</h4>
                                    <ul className={styles.skillList}>
                                        <li>ES6+</li>
                                        <li>React</li>
                                        <li>Gatsby</li>
                                        <li>Nunjucks</li>
                                        <li>GSAP</li>
                                        <li>jQuery</li>
                                        <li>NodeJS</li>
                                    </ul>
                                </li>
                                <li>
                                    <h4 className={styles.skillTitle}>Platforms</h4>
                                    <ul className={styles.skillList}>
                                        <li>SAP Commerce Cloud (Hybris)</li>
                                        <li>Umbraco</li>
                                        <li>Intershop</li>
                                        <li>DemandWare</li>
                                        <li>Kentico</li>
                                        <li>Shopify</li>
                                        <li>Wordpress</li>
                                    </ul>
                                </li>
                                <li>
                                    <h4 className={styles.skillTitle}>Tooling</h4>
                                    <ul className={styles.skillList}>
                                        <li>Webpack</li>
                                        <li>Gulp</li>
                                        <li>Grunt</li>
                                        <li>Babel</li>
                                        <li>Git</li>
                                        <li>NPM</li>
                                        <li>CLI</li>
                                    </ul>
                                </li>
                            </ul>

                        </Col>
                    </Grid>
                </section>
            </Page>
        );
    }
};

// Expertise.propTypes = {
    
// };

export default Expertise;