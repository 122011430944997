import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Grid.module.scss';

const Grid = ({ children, tag: Tag }) => {
    return (
        <Tag className={styles.grid}>
            {children}
        </Tag>
    );
};

Grid.defaultProps = {
    tag: 'div'
};

Grid.propTypes = {
    tag: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};

const Col = ({children, span }) => {
    const className = classnames(styles.col, {
        [ styles['colSpan' + span ] ] : !!span
    });

    return (
        <div className={className}>
            {children}
        </div>
    );
};

Col.propTypes = {
    children: PropTypes.node.isRequired,
    span: PropTypes.number
};

export { Grid as default, Col };